<app-messages></app-messages>
<mat-toolbar color="primary" class="p-0 m-0 d-inline-block">

  <div class="col-md-12">
    <!-- <div style="position: absolute;z-index: 99;left: 30%; margin-top: 10px; max-width: 90%;">
      <div>
         <button 
            type="button" 
            class="btn btn-primary" 
            appBsTemplatePopover
            data-bs-toggle="popover" data-bs-trigger="hover"
            data-bs-placement="left"
            title="Left Popover"
            [popoverTemplate]="dynamicContentTemp">
         Hover over me
         </button>
         <ng-template #dynamicContentTemp>
            <div>
               <h5>Popover Template Title</h5>
               <p>This is content rendered from an Angular template!</p>
            </div>
         </ng-template>
      </div>
      <div>
         <button
         type="button"
         class="btn btn-primary"
         appDynamicPopover
         [popoverTrigger]="'hover'"
         [popoverPlacement]="'right'"
         [popoverTitle]="'Dynamic Template Content'"
         [popoverContentTemplate]="dynamicContent">
         hover me
         </button>
         <ng-template #dynamicContent>
            <div>
               <h5>Popover Template Title</h5>
               <p>This is content rendered from an Angular template!</p>
            </div>
         </ng-template>
      </div>
      <div appBsPopover>
         <h3 class="text-center text-light"> 
            <button type="button" class="btn btn-warning"
               data-bs-toggle="popover" data-bs-trigger="hover"
               data-bs-placement="left" title="Left Popover"
               data-bs-content="This is the content of the Left Popover."> 
            Hover me (Left) 
            </button> 
         </h3>
         <h3 class="text-center text-light"> 
            <a href="javascript:void(0)" class="btn btn-danger"
               data-bs-toggle="popover" data-bs-trigger="hover"
               data-bs-placement="right" title="Right Popover"
               data-bs-content="This is the content of the Right Popover."> 
            Hover me (Right) 
            </a> 
         </h3>
         <button type="button" 
            class="btn btn-success" 
            data-bs-toggle="popover" 
            title="Custom Title" 
            data-bs-content="Custom styled popover content">
         Hover for custom popover
         </button>
         <button type="button" class="btn btn-primary" data-bs-toggle="popover" data-bs-content="This is a popover!">
         Click me
         </button>
      </div>
   </div> -->
    <div class=" header-flex" >

      <!-- <div class="img-fluid p-2 me-auto" >
        <img class="header-app-logo ms-2" [src]="common.webLogo" (click)="logoClicked()"> 
      </div> -->
      
      <div class="flex-spacer"></div>

        <div class="">
          <current-date-time></current-date-time>     
          <div class="marquee_St" *ngIf="common?.isOrganizationSuspend">
            <span>
              <marquee behavior=scroll direction="left" scrollasmount="5" onMouseOver="this.stop()" onMouseOut="this.start()" style="color: red;">Your system has limited functionality. Please contact your organization owner to rectify this.<img (click)="common.isOrganizationSuspendAlert()" class="marquee_info_ic" src="assets/images/info-icon-red.svg" /></marquee>
            </span>
          </div>     
        </div>
        
        <div class="flex-spacer"></div>
      <!-- <ng-container *ngIf="session.get('user_type') !== 'Health Technician'">
        <div class="approval-status" [ngClass]=" session.get('orgStatus') == 'Pending for Approval'?'pending-approval':''" *ngIf="session.get('orgStatus') == 'Pending for Approval' && !dismiss"> 
          <p class="approval-text">Your account is under approval process. If it is taking time, please make sure you have provided all the required information and uploaded mandatory documents.</p>
          <p class="approval-link" routerLink="/dashboard/profile">Upload Here</p>
          <a class="cursor-pointer close" (click)="dismissMsg()"><mat-icon>close</mat-icon></a>
        </div>
        <div class="approval-status docexpiry" *ngIf="session.get('orgStatus') == 'Approved' && session.get('docExpiry') == true && !dismiss" >
          <p class="approval-text">Your organisation's document has expired. Kindly upload a valid document.  Failing to upload the document beyond a limited time may cause suspension of your account.</p>
          <p class="approval-link" routerLink="/dashboard/profile">Upload Here</p>
          <a class="cursor-pointer close" (click)="dismissMsg()"><mat-icon>close</mat-icon></a>
        </div>
      </ng-container> -->
     
      <!-- old code for notification -->
      <!-- <div class="p-2 notification-header" (click)="getCount()">
          
        <i [matMenuTriggerFor]="beforeMenu"  class="material-icons cursor-pointer mt-1 me-1" title="{{'common.labels.Notifications' | translate}}">
          notifications_none
        </i>
        <span *ngIf="service.common.allNotificationsCount" class="notify-badge">{{service.common.allNotificationsCount}}</span>
        <mat-menu #beforeMenu="matMenu" xPosition="before" class="dropdown-width">
            <div class="arrow"></div>
          <div class="d-flex flex-row w-100 p-3" [ngClass]="{'justify-content-between': service.common.allNotificationsCount  == 0}">
              
            <span *ngIf="userType === 'Staff'" [ngClass]="service.common.allNotificationsCount == 0?'d-none':''">
              <div *ngIf="service.common.allNotificationsCount" class="primary-color curser" routerLink="/dashboard-technician/technician-notification"> 
                {{'main.labels.Notification' | translate}} ({{service.common.allNotificationsCount}})
              </div>
            </span>
            <span *ngIf="userType !== 'Customer'" [ngClass]="service.common.allNotificationsCount == 0?'d-none':''">
              <div *ngIf="service.common.allNotificationsCount" class="primary-color curser" routerLink="/dashboard/notification"> 
                {{'main.labels.Notification' | translate}} ({{service.common.allNotificationsCount}})
              </div>
            </span>
            <span *ngIf="userType !== 'Staff' && userType !== 'Customer'" [ngClass]="service.common.allNotificationsCount == 0?'d-none':''">
              <div *ngIf="service.common.allNotificationsCount" class="primary-color curser" routerLink="/dashboard/notification"> 
                {{'main.labels.Notification' | translate}} ({{service.common.allNotificationsCount}})
              </div>
            </span>
            <div *ngIf="!service.common.allNotificationsCount" class="primary-color curser" > {{'main.labels.Notification' | translate}} 
                <div class="no-noti mt-3">  {{'common.labels.No notifications present' | translate}} </div>
            </div>
          </div>
          <div mat-menu-item class="mat-btn-height mb-3" *ngFor = "let messages of notification ">
            <div class="row">
              <div class="col-md-2 ps-3" >
                <i class="material-icons align-midddle icon-notification" >
                  notifications_none
                </i>
              </div>
              <div class="col-md-10"> 
                <div *ngIf="userType !== 'Staff' && userType !== 'Customer'" class="row" routerLink="/dashboard/notification">
                  <div class="col-sm-12">
                      <span class="short-msg"><p>{{messages.shortMessage}}</p></span>
                  </div>
                  <div class="col-sm-12"> 
                    <small class="color-light msg">{{'common.labels.Click to see more' | translate}} .. </small>
                  </div>
                </div>
                <div *ngIf="userType === 'Customer'" class="row" routerLink="/dashboard/notification">
                  <div class="col-sm-12">
                      <span class="short-msg"><p>{{messages.shortMessage}}</p></span>
                  </div>
                  <div class="col-sm-12"> 
                    <small class="color-light msg">{{'common.labels.Click to see more' | translate}} .. </small>
                  </div>
                </div>
                <div *ngIf="userType === 'Staff'" class="row" routerLink="/dashboard-technician/technician-notification">
                  <div class="col-sm-12">
                      <span class="short-msg"><p>{{messages.shortMessage}}</p></span>
                  </div>
                  <div class="col-sm-12"> 
                    <small class="color-light msg">{{'common.labels.Click to see more' | translate}} .. </small>
                  </div>
                </div>
              </div>
             <div class="clearfix"></div>
            </div>
          </div>
         
          <button *ngIf="service.common.allNotificationsCount" mat-menu-item class="text-center">
            <div class="row">
               <div class="col">
                  <div *ngIf="userType !== 'Staff' && userType !== 'Customer'" class="view-all" routerLink="/dashboard/notification">{{'common.labels.View All' | translate}}</div>
                  <div *ngIf="userType === 'Customer'" class="view-all" routerLink="/dashboard/notification">{{'common.labels.View All' | translate}}</div>
                  <div *ngIf="userType === 'Staff'" class="view-all" routerLink="/dashboard-technician/technician-notification">{{'common.labels.View All' | translate}}</div>
               </div>
            </div>
          </button>
          <div *ngIf="!service.common.allNotificationsCount" class="view-all" ></div>
          
        </mat-menu>
      </div> -->


      <!-- new code for notification -->
       <!-- <div>
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Agency</mat-label>
          <mat-select (selectionChange)="onChangeOrganization($event)">
             <mat-option value="All">V Mart</mat-option>
             <mat-option value="Paid">D Mart</mat-option>
             <mat-option value="Unpaid">Value Plus</mat-option>
          </mat-select>
        </mat-form-field>
       </div> -->

       <div *ngIf="organizationListAgency && organizationListAgency.length > 0" [class.me-2]="notificationCount > 0">
        <div class="dropdown">
          <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </button> -->
          <span title="Change Agency" type="button" class="cursor-pointer pointer me-2" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="material-icons">workspaces</i>
          </span>          
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <ng-container *ngFor="let agency of organizationListAgency">
              <li>
                <button class="dropdown-item" [class.active]="orgCode === agency.organizationCode" type="button" (click)="(orgCode === agency?.organizationCode) ? $event.stopPropagation() : onChangeOrganization(agency)" [value]="agency.organizationCode">{{agency?.organizationName}}</button>
              </li>
            </ng-container>            
          </ul>
        </div>
       </div>

      <app-short-notification *ngIf="!isNewStaff"
       (updateParentNotificationsList)="updateParentNotificationsList($event)"
       ></app-short-notification>

      <!-- <div class="p-2 notification-header">
          
        <span *ngIf="common.notificationList?.count" class="position-absolute translate-middle badge rounded-pill bg-danger ng-star-inserted">{{common.notificationList?.count}}</span>
        <i [matMenuTriggerFor]="beforeMenu"  class="material-icons cursor-pointer mt-1 me-1" title="{{'common.labels.Notifications' | translate}}">
          notifications_none
        </i>
        
        <mat-menu #beforeMenu="matMenu" xPosition="before" class="dropdown-width">
        <div class="container notification-body">
          <div class="row">
            <div class="row">
             <div class="col-lg-7 dialog-header">
              Notification {{common.notificationList?.count ? '('+common.notificationList?.count+')' : ''}}
             </div>
             <div class="col-lg-5 text-end">
              <a *ngIf="common.notificationList?.data?.length > 0" mat-flat-button (click)="navigate()">View All</a>
             </div>
            </div>
           <ng-template [ngIf]="common.notificationList?.data?.length > 0" [ngIfElse]="noNotification">
             <ul>
              <li *ngFor="let notification of common.notificationList?.data">
               <div class="row">

                <div class="row">
                  <div class="col-lg-7">
                    <span>
                      {{notification.shortMessage}}
                    </span>
                  </div>
                  <div class="col-lg-5 text-end">
                 <small>{{notification.notificationDate}}</small>
                 </div>
                </div>

                <div class="col-lg-12">
                  <small>
                    {{notification.description}}
                  </small>
                </div>
               </div>
              </li>
             </ul>
           </ng-template>

           <ng-template #noNotification>
          <div class="row">
            <div class="text-center text-danger">
              
              <mat-icon>notifications_off</mat-icon>
              <br>
              <span>No notifications are present.</span>
            </div>
          </div>
           </ng-template>

          </div>
        </div>
       </mat-menu>
      </div> -->


      <div class="p-1 dropdown userDropdown">
        <mat-toolbar class="cursor-pointer avatarBox dropdown-toggle" id="dropdownMenuButtonHeader" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="userTitle">{{loggedUserData?.name ? (loggedUserData.name | capitalize) : loggedUserData?.organizationList[0]?.organizationName }}</span>
        </mat-toolbar>
        <ul class="dropdown-menu mt-1" aria-labelledby="dropdownMenuButtonHeader">
         
          <ng-template [ngIf]="userType === 'Staff'"  [ngIfElse]="OtherLoggedIn_MenuView">
          <div class="w-100 ps-2 pe-2" [routerLink]="['/dashboard-technician/self/update']" [queryParams]="{ workerCode: loggedIn_userCode}">
            <div class="w-100 bgTxt avatarBox cursor-pointer">

              <img alt="No Image" title="{{loggedUserData?.name ? (loggedUserData.name | capitalize) : ''}} "
               [src]="loggedUserData?.userImage ? baseURL + loggedUserData.userImage : 'assets/common_images/profile.jpg'" 
               class="rounded-circle no-image img-fluid cursor-pointer user-image"
               onerror="this.src='assets/common_images/profile.jpg';">

          <p class="userTitle pe-3 m-0">{{loggedUserData?.name | capitalize }} <br />
            <span *ngIf="LoggedUserRole">{{LoggedUserRole}}</span>
          </p>

            </div>
            <hr class="mt-2 mb-2">
          </div>
        </ng-template>

        <ng-template #OtherLoggedIn_MenuView>
          <div class="w-100 ps-2 pe-2">
            <div class="w-100 bgTxt avatarBox cursor-pointer">
              <img alt="No Image" title="{{loggedUserData?.name ? (loggedUserData.name | capitalize) : ''}} " 
              [src]="loggedUserData?.userImage ? baseURL + loggedUserData.userImage : 'assets/common_images/profile.jpg'"
               class="rounded-circle no-image img-fluid cursor-pointer user-image"
               onerror="this.src='assets/common_images/profile.jpg';">

          <p class="userTitle pe-3 m-0">{{loggedUserData?.name | capitalize }} <br />
            <span *ngIf="LoggedUserRole">{{LoggedUserRole}}</span>
          </p>

            </div>
            <hr class="mt-2 mb-2">
          </div>
        </ng-template>

           

          <!-- <div class="w-100 ps-2 pe-2" *ngIf="loggedUserData?.organizationList?.length > 0">
            <div class="w-100 bgTxt avatarBox cursor-pointer" routerLink="/dashboard/profile">
              <img alt="No Image" title="{{loggedUserData?.organizationList[0]?.organizationName ? (loggedUserData?.organizationList[0]?.organizationName | capitalize) : ''}} " [src]="organizationLogo ? baseURL + organizationLogo : 'assets/common_images/profile.jpg'" class="rounded-circle no-image img-fluid cursor-pointer user-image">
          <p class="userTitle pe-3 m-0">{{loggedUserData?.organizationList[0]?.organizationName | capitalize }}<br /><span>Organisation</span></p>
            </div>
            <hr class="mt-2 mb-2">
          </div>
          <li><a class="dropdown-item py-0 my-0" href="javascript:void(0)" routerLink="/dashboard/settings"><i class="material-icons">settings</i><span>Settings</span></a></li> -->
          
          <ng-template [ngIf]="userType !== 'Staff' && userType !== 'Customer'">
            <div class="w-100 ps-2 pe-2" *ngIf="loggedUserData?.organizationList?.length > 0">
              <div class="w-100 bgTxt avatarBox cursor-pointer" routerLink="/dashboard/profile">
              
                <img alt="No Image" title="{{loggedUserData?.organizationList[0]?.organizationName ? (loggedUserData?.organizationList[0]?.organizationName | capitalize) : ''}} "
                 [src]="organizationLogo ? baseURL + organizationLogo : 'assets/common_images/profile.jpg'"
                  class="rounded-circle no-image img-fluid cursor-pointer user-image"
                  onerror="this.src='assets/common_images/profile.jpg';">

            <p class="userTitle pe-3 m-0">{{loggedUserData?.organizationList[0]?.organizationName | capitalize }}<br /><span>Organisation</span></p>
              </div>
              <hr class="mt-2 mb-2">
            </div>
          </ng-template>
            
          <ng-template [ngIf]="userType !== 'Staff' && userType !== 'Customer'">
            <ng-container *ngFor="let menu of minuList; index as idx">
              <li *ngIf="menu.viewPermission && menu.menuPosition === 'Header'">
                <a class="dropdown-item py-0 my-0" href="javascript:void(0)" routerLink="{{menu.pageUrl}}">
                  <img class="billIc" [src]="menu?.icon ? (baseURL + menu.icon + '.svg') : baseURL + menu.icon + '.svg'"
                  alt="{{menu.icon}}" /><span>{{menu.pagePermissionName}}</span>                  
                  <small style="height: max-content;" *ngIf="menu.pagePermissionName === 'Billing' && common?.gigflexPendingPaymentCount && common.gigflexPendingPaymentCount > 0"
                    class="ms-0 badge rounded-pill bg-danger badge-short-nav">
                    {{common?.gigflexPendingPaymentCount}}
                  </small>
                </a>
              </li>
            </ng-container>
            </ng-template>

            <!-- <ng-template [ngIf]="userType === 'Staff' && userType !== 'Customer'"> -->
              <ng-template [ngIf]="userType === 'Staff'">
              <li>
                <a class="dropdown-item py-0 my-0" href="javascript:void(0)" routerLink="/dashboard-technician/tech-setting"><i class="material-icons">settings</i><span>Settings</span>
                </a>
              </li>
              </ng-template>

          <li (click)="logout()"><a class="dropdown-item py-0 my-0" href="javascript:void(0)">
            <i class="material-icons small-icon">power_settings_new</i><span>Logout</span></a></li>
        </ul>
      </div>
    </div>
  </div>

</mat-toolbar>

