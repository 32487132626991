//import { BroadcastMessageComponent } from './../../dashboards/providers-dashboard/broadcast-message/broadcast-message.component';
import { Component, OnInit, ViewChild, Output, Renderer2, Inject, OnDestroy, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/dashboards/providers-dashboard/notification/notification.service';
import { SessionStorageService } from 'angular-web-storage';
// import { ProcedureEditComponent } from 'src/app/dashboards/providers-dashboard/procedure/procedure-edit/procedure-edit.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { MatDialog } from '@angular/material/dialog';
// import { RxStompService } from "@stomp/ng2-stompjs";
import { RxStompService } from 'src/app/rx-stomp.service';
// import { OrgPopupComponent } from 'src/app/dashboards/technician-dashboard/org-popup/org-popup.component';
import { ConfirmLogoutComponent } from '../confirm-logout/confirm-logout.component';
import { AuthService } from 'src/app/shared/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/authentication/login/login.service';
import { ApiRootService } from 'src/app/shared/api-root.service';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationCount = 0;
  page = 0;
  limit = 5;
  notification;
  count;
  userType;
  LoggedUserRole: any;
  // @ViewChild(ProcedureEditComponent, { static: true }) procedure: ProcedureEditComponent;
  topicName = 'chat_';
  orgCodes = [];
  orgCode: any;
  dismiss = false;
  loggedUserData: any;
  baseURL: any;
  organizationLogo: any;
  notificationList:any
  loggedIn_userCode:string;
  organizationListAgencyData: any;
  organizationListAgency = [];

  isPermissionForSettings:boolean = false ;
  isPermissionForBilling: boolean = false;
  minuList: any;

 private destroyObs  = new Subject<string>();
 isNewStaff : boolean = false;

  constructor(public service: NotificationService, public session: SessionStorageService,
     private translate:TranslateService, public common: CommonService,
     public dialog: MatDialog, public rxStompService: RxStompService,
     private auth: AuthService, private _router: Router,
       private loginService: LoginService,
       public api_service: ApiRootService,
    )
     {
      this.baseURL = api_service.getSuperAdmin() + "/downloadFile/";
    this.translate.setDefaultLang(this.service.session.get('language'));
    // this.router.events.subscribe((val)=>{
    //   this.dismiss = false;
    // });
  }
 
 
  ngOnInit() {
 
    if(this.session.get("isNewStaff") && (this.session.get("isNewStaff") == 'true' || this.session.get("isNewStaff") == true))
    {
      this.isNewStaff = true;
    }
 
    const isOrganizationSuspend = this.session.get('isOrganizationSuspend');
    if(isOrganizationSuspend){
      this.common.isOrganizationSuspend = true;
    } else {
      this.common.isOrganizationSuspend = false;
    }

    this.organizationListAgencyData = this.service.session.get('org_list_agency');
    this.organizationListAgency = this.organizationListAgencyData?.orgList;
    this.baseURL = this.service.service.getSuperAdmin() + "/downloadFile/";
 
    this.topicSub();

    this.userType = this.session.get('user_type');
    this.orgCodes= this.session.get('org_list');
    this.LoggedUserRole = this.session.get('MenuListDataAndPagePermission')?.role;
    this.orgCode= this.session.get('orgCode');
    this.loggedUserData = this.session.get('loggedUserData');
    this.getSettingBySettingName('Organization Logo');
    this.loggedIn_userCode = this.session.get('userCode');

    if(this.session.get('MenuListDataAndPagePermission'))
    {
      // this.isPermissionForSettings = this.session.get('MenuListDataAndPagePermission').ppLst.some(
      //   ele => ele.pagePermissionName.toLowerCase() === 'settings' && ele.menuPosition === 'Header'
      //   );
      //   this.isPermissionForBilling = this.session.get('MenuListDataAndPagePermission').ppLst.some(
      //     ele => ele.pagePermissionName.toLowerCase() === 'billing' && ele.menuPosition === 'Header'
      //     );
      this.minuList = this.session.get('MenuListDataAndPagePermission').ppLst;
    }

    if(this.userType === "Customer")
      {
        this.common.isAllowAutoApproval();
      }
    

  }
  

  /* Name:Shailesh
      use: for getting notification*/
  // getNotification(page, limit) { 
  //   try {
  //      this.service.getAllHealthcareNotificationByUserCodeWithReadByPage(this.session.get('userCode'), page, limit, (response) => {
  //    //console.log(response);
  //    if(response.responsecode === 200){
  //     this.notification = response.data;
  //     // ////console.log(this.notification);
  //     this.service.common.allNotificationsCount = response.count;
  //     ////////console.log(this.count);
  //    } else {
  //      this.notification = [];
  //      this.service.common.allNotificationsCount = '';
  //    }
  //   })
  //   } catch (error) {
  //     //console.log(error)
  //   }
   
  // }

  // getCount() {
  //   this.getNotification(this.page, this.limit);
  //   // //////console.log(this.count);
  // }

  confirmPopup(){
    //////console.log(this.router.url);
    this.service.common.confirmEmit.emit('showpopup');
  }

  onChangeOrganization(event: any){
    console.log('OnChangeOrganization: ', event);
    this.changeOrgSession(event);
  }

  updateParentNotificationsList(event){
    console.log('UpdateParentNotificationsList: ', event.count);
    if(event.count && event.count > 0){
     this.notificationCount = event.count;
    } else {
      this.notificationCount = 0;
    }
  }

  /* Name:Shailesh
      use: to open broadcast message popup */
  // sendBrdCstMsg(): void {
  //   const dialogRef = this.dialog.open(BroadcastMessageComponent, {
  //     width: '550px',
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     // ////console.log('The dialog was closed');  
  //   });
  // }

  /* Name:Shailesh
      use: for getting chat message count */
  topicSub(){
    //console.log("from header comp sub start", '/topic/' + this.topicName + this.session.get('userCode'));

    if(this.session.get("isNewStaff") && (this.session.get("isNewStaff") == 'true' || this.session.get("isNewStaff") == true)) return ;

    try {
      if(!this.common.allGroupResponse){
        // console.log('allGroupResponse_header: ', this.common.allGroupResponse);
        this.common.getGlobalCountChat();
        this.common.getAllChatGroupGlobalChat();
      }
        this.rxStompService.watch('/topic/' + this.topicName + this.session.get('super_admin_code'))
        .pipe(takeUntil(this.destroyObs)).subscribe((message) => {
      ////console.log(message);
      //let body = JSON.parse(message.body);
      // console.log("from header comp sub data", '/topic/' + this.topicName + this.session.get('userCode'));
      // console.log(body); 
      this.common.getAllChatGroupGlobalChat(true);
    });
    } catch (error) {
      //console.log(error)
    }
  
  }

  getSettingBySettingName(settingName: any){
    try {
      this.common.getSettingBySettingName(settingName, this.orgCode, (response)=>{
        // //console.log('getWebLogo', response);
     if(response.responsecode == 200){
       this.organizationLogo = response.settingValue;
     } else {
       
     }
   })
   } catch (error) {
     //console.log(error)
   }
  }

    /* Name:Shailesh
      use: to logout from application */
      logout() {
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '320px',
          data: { msg: 'Are you sure you want to logout?' }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.auth.logout();
          }
        });
      }

          /* Name:Shailesh
      use: to logout from application */
      changeOrgSession(event) {
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '320px',
          data: { msg: 'Are you sure you want to change agency?' }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.clearReturnUrl();
            this.auth.changeOrgSession();
            this.callChildFunction(event);
          }
        });
      }

      clearReturnUrl(): void {
        this.common.progressLoader = true;
        this._router.navigate(['/AuthReturn']);
        
      }

      callChildFunction(event): void {
        // console.log('callChildFunction: ', event.organizationCode, this.organizationListAgencyData.orgEmail);
        if(event?.organizationCode && this.organizationListAgencyData?.orgEmail){
          this.loginService.onOrganizationSelect(event.organizationCode, this.organizationListAgencyData.orgEmail);
        }
      }


  // changeOrg(){
  //   const dialogRef = this.dialog.open(OrgPopupComponent, {
  //     width: '300px',
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     //console.log(result);
  //     if(result){
  //     this.common.orgCodeEmit.emit(result);   
  //     }
  //   });
  // }

  // dismissMsg(){
  //   this.dismiss = true;
  // }

  // navigate(){

  //   if(this.service.session.get('user_type') === 'Customer')
  //   {
  //     this._router.navigate(['/dashboard-customer/notification'])
  //   }
  //   else if(this.service.session.get('user_type') === 'Staff')
  //   {
  //     this._router.navigate(['/dashboard-technician/notification'])
  //    }

  //   else{
  //     this._router.navigate(['/dashboard/notification'])
  //    }

  // }


  logoClicked()
  {
    if(this.service.session.get('user_type') === 'Staff')
    {
      this._router.navigate(['/dashboard-technician'])
    }
    else
    {
      let isPermissionForDashboar_View  = this.common.getBtnEventPermissions("Dashboard");

        if(isPermissionForDashboar_View.viewPermission === true)
        {
            if(this.service.session.get('user_type') === 'Customer')
            {
            this._router.navigate(['/dashboard-customer'])
            }
            else{
              this._router.navigate(['/dashboard'])
             }

        }
    }
  }


  ngOnDestroy(): void {
    this.destroyObs.next('');
    this.destroyObs.complete();
  }

}
